import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { ModalDeleteComponent } from './modals/modal-delete/modal-delete.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { InspectionModalComponent } from './modals/inspection-modal/inspection-modal.component';
import { PhotosModalComponent } from './modals/photos-modal/photos-modal.component';
import { InsuredValueComponent } from './modals/insured-value/insured-value.component';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PaymentsModalComponent } from './payments-modal/payments-modal.component';
import { VehicleModalComponent } from './modals/vehicle-modal/vehicle-modal.component';



@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgxMaskModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ModalDeleteComponent,
    ConfirmModalComponent,
    InspectionModalComponent,
    PhotosModalComponent,
    InsuredValueComponent,
    PaymentsModalComponent,
    VehicleModalComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ModalDeleteComponent,
    PaymentsModalComponent,
    VehicleModalComponent,
  ]
})
export class ComponentsModule { }
