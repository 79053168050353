import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class PoliciesService {

	private headers: any;

	constructor(
		private http: HttpClient,
	) {
		this.headers = new HttpHeaders();
		this.headers = this.headers.set('Content-Type', 'application/json');
	}

	public get(offset, query: string): Observable<any> {
		return this.http.get(`${environment.apiUrl}policies?offset=${offset}&limit=10&query=${query}`, { headers: this.headers });
	}

	public getById(id): Observable<any> {
		return this.http.get(`${environment.apiUrl}policies/` + id, { headers: this.headers });
	}
	public getActivations(id): Observable<any> {
		return this.http.get(`${environment.apiUrl}policies/${id}/activations`, { headers: this.headers });
	}

	public getByUser(offset, id): Observable<any> {
		return this.http.get(`${environment.apiUrl}policies?client=${id}&offset=${offset}&limit=10`, { headers: this.headers });
	}
	public getByAgent(offset, id): Observable<any> {
		return this.http.get(`${environment.apiUrl}policies?agent=${id}&offset=${offset}&limit=10`, { headers: this.headers });
	}

	public getInvoices(id): Observable<any> {
		return this.http.get(`${environment.apiUrl}invoices/` + id, { headers: this.headers });
	}

	public updateCreditCard(creditCardId: string, policyId: string): Observable<any> {
		return this.http.put(`${environment.apiUrl}policies/${policyId}/credit-card/${creditCardId}`, {});
	}

	public updateVehicle(vehicle: any, policyId: string): Observable<any> {
		return this.http.put(`${environment.apiUrl}policies/${policyId}`, vehicle);
	}
	

}
